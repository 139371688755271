<template>

    <router-view class="chatroom" />

</template>

<script>
  export default {
    provide () {
      return {
        $error() {
          //
        },
      }
    },
  }
</script>


<style lang="scss">

/* width */
::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCC;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #AAA;
}

html {
  scroll-behavior: smooth;

  &.unsmooth {
    scroll-behavior: initial;
  }
}
.background {
  background-color: #383838;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loading {
  margin-left: -15px;
  margin-top: -15px;
}

.modal-backdrop {
  opacity: 0.5;
}

.form-control {
  border-radius: 0.8rem !important;
  background-color: #f4f6f9;
  border-color: #f4f6f9;
  transition: all;
  transition-duration: 0.3s;
  font-size: 15px;
  &:focus {
    border-color: #2196f3;
    background: none;
  }
}

.btn,
.card,
.modal-content {
  border-radius: 0.8rem ;
}

.modal-dialog{
  padding-left: 2rem;
  padding-right: 2rem;
}

label {
  line-height: 20px;
  font-weight: bold !important;
  margin: 0;
}

.list-group {
  border: none;
  .list-group-item {
    border: none;

    svg {
      height: 18px;
    }


  }
}

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  .b-avatar-custom,
  .b-avatar-img,
  .b-avatar-text {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    mask-image: radial-gradient(#fff, #000);
  }
  .b-avatar-img img {
    width: 100%;
    height: 100%;
    max-height: auto;
    border-radius: inherit;
    object-fit: cover;
  }
}
.chatroom {

  display: flex;
  flex-wrap: nowrap;



  margin: auto;

  header {
    background: linear-gradient(90deg, #1e88e5 0%, #2196f3 100%), #1976d2;
    color: #fff;
    text-align: center;
    height: 45px;
    white-space: nowrap;

    > span {
      width: 25%;
    }

    .btn {
      line-height: 20px;
      font-size: 15px;
    }
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
    svg {
      height: 15px;
    }
    .message {
      position: relative;
      .badge {
        padding: 1px 3px;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  body {
    font-size: 1.2rem;
  }

  .customer-tab.nav-tabs {
    border: none;
    .nav-link {
      display: block !important;
      border: none;
      border-bottom: solid 2px #eee;
      padding: 0.8rem;
      margin-bottom: 0 !important;
      font-size: 15px;
      &.active {
        font-weight: bold;
        border: none;
        border-bottom: solid 2px #000;
      }
    }
  }

  .custom-select {
    background-color: #f4f6f9;
    border-color: #f4f6f9;
    &:focus {
      border-color: #2196f3;
      background: none;
    }
  }
}
</style>
